<template>
  <div class="online-classes-page">
    <v-row justify="center">
      <v-col md="6">
        <div class="px-2 pt-2">
          <h3 class="mb-1">Scheduled Classes</h3>
          <!-- online classes list -->
          <intract-smart-list
            v-if="shouldProceed"
            ref="online-classes-smart-list"
            disable-search
            paginated
            no-spacing
            :endpoint="onlineClassesEndpoint"
          >
            <template v-slot:list-item="{ item: conference }">
              <v-card class="mt-2">
                <v-list-item @click="goToMeeting(conference)">
                  <v-list-item-content>
                    <v-list-item-subtitle
                      ><strong>{{ conference.subject.name }}</strong> -
                      {{
                        conference.subject.room ? conference.subject.room.sections : "Extra Subject",
                      }}</v-list-item-subtitle
                    >
                    <v-list-item-title class="text-wrap">{{
                      conference.title
                    }}</v-list-item-title>
                    <v-list-item-subtitle class="text-wrap">{{
                      conference.creator.full_name
                    }}</v-list-item-subtitle>
                    <v-divider></v-divider>
                    <v-list-item-subtitle
                      class="text-wrap font-weight-bold primary--text"
                      ><span
                        v-if="
                          moment(conference.start_datetime).date() ==
                          moment(conference.end_datetime).date()
                        "
                        >{{ moment(conference.start_datetime).format("lll") }} -
                        {{
                          moment(conference.end_datetime).format("hh:mm A")
                        }}</span
                      ><span v-else
                        >{{ moment(conference.start_datetime).format("lll") }} -
                        {{
                          moment(conference.end_datetime).format("lll")
                        }}</span
                      ></v-list-item-subtitle
                    >
                  </v-list-item-content>
                  <v-list-item-icon class="align-self-center">
                    <intract-drop-down-menu
                      v-if="
                        conference.creator.id == currentUser.id ||
                        currentUser.is_admin
                      "
                      :options-list="getDropdownOptions(conference)"
                    />
                  </v-list-item-icon>
                </v-list-item>
              </v-card>
            </template>
            <template v-slot:paginated-no-items-found>
              <p class="mb-2">No Online Classes Found!</p>
              <v-btn
                color="primary"
                class="mb-4"
                v-if="!currentUser.is_student"
                @click="showCreationOptions = true"
                >Create Online Class</v-btn
              >
            </template>
          </intract-smart-list>
          <div
            class="text-center"
            v-if="!showPreviousOnlineClasses && !isLoading && selectedRoom"
          >
            <v-btn
              text
              color="primary"
              @click="showPreviousOnlineClasses = true"
              >Show previous online classes</v-btn
            >
          </div>
          <div v-if="showPreviousOnlineClasses" class="ptn-2">
            <h3 class="mb-2">Previous Online Classes</h3>
            <intract-smart-list
              v-if="shouldProceed"
              no-spacing
              ref="previous-online-classes-smart-list"
              disable-search
              paginated
              :endpoint="previousOnlineClassesEndpoint"
            >
              <template v-slot:list-item="{ item: conference }">
                <v-card class="mt-2">
                  <v-list-item @click="goToMeeting(conference)">
                    <v-list-item-content>
                      <v-list-item-subtitle
                        ><strong>{{ conference.subject.name }}</strong> -
                        {{
                          conference.subject.room ? conference.subject.room.sections : "Extra Subject"
                        }}</v-list-item-subtitle
                      >
                      <v-list-item-title class="text-wrap">{{
                        conference.title
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="text-wrap">{{
                        conference.creator.full_name
                      }}</v-list-item-subtitle>
                      <v-divider></v-divider>
                      <v-list-item-subtitle
                        class="text-wrap font-weight-bold primary--text"
                        ><span
                          v-if="
                            moment(conference.start_datetime).date() ==
                            moment(conference.end_datetime).date()
                          "
                          >{{ moment(conference.start_datetime).format("lll") }}
                          -
                          {{
                            moment(conference.end_datetime).format("hh:mm A")
                          }}</span
                        ><span v-else
                          >{{ moment(conference.start_datetime).format("lll") }}
                          -
                          {{
                            moment(conference.end_datetime).format("lll")
                          }}</span
                        ></v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-icon class="align-self-center">
                      <intract-drop-down-menu
                        v-if="
                          conference.creator.id == currentUser.id ||
                          currentUser.is_admin
                        "
                        :options-list="getDropdownOptions(conference)"
                      />
                    </v-list-item-icon>
                  </v-list-item>
                </v-card>
              </template>
              <template v-slot:paginated-no-items-found>
                <p class="mb-2">No Previous Online Classes Found!</p>
              </template>
            </intract-smart-list>
          </div>
          <!-- creation options -->
          <intract-list-sheet
            :visible="showCreationOptions"
            :list="creationOptions"
            @close="showCreationOptions = false"
          />
        </div>
      </v-col>
    </v-row>

    <!-- intract create conference sheet -->
    <intract-create-edit-sheet
      v-if="shouldProceed"
      key="conference-create-edit-sheet"
      ref="conference-create-edit-sheet"
      :title="
        createEditConferenceSheet.editId
          ? 'Edit Online Class'
          : 'Create Online Class'
      "
      :description="createEditDescription"
      persistent
      :visible="createEditConferenceSheet.visible"
      @close="
        createEditConferenceSheet.visible = false;
        createEditConferenceSheet.editId = null;
        createEditConferenceSheet.isGoogleMeet = false;
      "
      :fields="formFields"
      :endpoint="endpoints.videoConferences"
      :edit-id="createEditConferenceSheet.editId"
      :data-object="createEditConferenceSheet.obj"
      create-success-message="Online Class successfully scheduled!"
      edit-success-message="Online Class successfully edited!"
      :custom-send-object-function="
        createEditConferenceSheet.isGoogleMeet ? createGoogleMeet : null
      "
      @objectCreated="onRefresh"
      @updateObject="(obj) => (createEditConferenceSheet.obj = obj)"
    >
    </intract-create-edit-sheet>

    <!-- create conference fab -->
    <v-fab-transition>
      <v-btn
        v-if="!isLoading && !currentUser.is_student"
        color="primary"
        class="non-focused"
        @click="showCreationOptions = true"
        dark
        right
        bottom
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <!-- select room dialog -->
    <choose-room-dialog
      :visible="showRoomDialog"
      @roomSelected="
        (r) => {
          selectedRoom = r;
          showRoomDialog = false;
        }
      "
    />

    <!-- delete confirmation dialog -->
    <confirmation-dialog
      title="Are you sure you want to delete this online class?"
      description="This action cannot be undone."
      :visible="showDeleteDialog"
      delete-dialog
      @successCallback="deleteOnlineClass"
      @failureCallback="showDeleteDialog = false"
    />

<!-- Copy to other Classroom dialog -->
        <v-dialog
            v-model="copyToOtherClassroomDialog.visible"
            width="500"
            persistent
          >
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                 <v-row>
                  <v-col cols="10">
                    <p class="mb-1" style="word-break: break-word">Copy to other classroom</p>
                  </v-col>
                </v-row>
               <v-btn icon absolute top right @click="copyToOtherClassroomDialog.visible=false; copyToOtherClassroomDialog.classroom=null; copyToOtherClassroomDialog.subject=null">
                 <v-icon>mdi-close</v-icon>
               </v-btn>
              </v-card-title>

              <v-card-text>
                <v-select
                  :items="allRooms"
                  color="primary"
                  v-model="copyToOtherClassroomDialog.classroom"
                  dense 
                  outlined
                  class="mt-3"
                  label="Select Classroom"
                  hide-details
                  item-value="id"
                  item-text="sections"
                  clearable
                ></v-select>

                <v-select
                  dense
                  outlined
                  class="mt-3"
                  label="Select Subject"
                  :items="roomSubjects"
                  v-model="copyToOtherClassroomDialog.classroomSubject"
                  item-text="name"
                  item-value="id"
                  :disabled="!copyToOtherClassroomDialog.classroom || isLoading"
                  clearable
                ></v-select>
              </v-card-text>
                  <!-- :items="roomSubjects.filter(subject => subject.id != homework.subject.id)" -->

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="mt-n7 mb-3"
                  color="primary"
                  block
                  :disabled="!(copyToOtherClassroomDialog.classroom && copyToOtherClassroomDialog.classroomSubject)"
                  @click="copyOnlineClass"
                >
                  Copy 
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog> 

  </div>
</template>
<script>
/* global gapi */
import Mixins from "@utils/mixins";
import { mapGetters } from "vuex";
import IntractDropDownMenu from "@components/generics/IntractDropDownMenu";
import ConfirmationDialog from "@components/dialogs/ConfirmationDialog";
import IntractSmartList from "@components/generics/IntractSmartList";
import IntractListSheet from "@components/generics/IntractListSheet";
import moment from "moment";
import ChooseRoomDialog from "@components/dialogs/ChooseRoomDialog";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { Share } from '@capacitor/share';

export default {
  name: "OnlineClasses",
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  components: {
    IntractSmartList,
    IntractListSheet,
    ChooseRoomDialog,
    IntractDropDownMenu,
    IntractCreateEditSheet,
    ConfirmationDialog,
  },
  data() {
    return {
      moment: moment,
      selectedRoom: null,
      devicePlatform: null,
      toBeDeletedOnlineClass: null,
      showPreviousOnlineClasses: false,
      showDeleteDialog: false,
      showRoomDialog: false,
      showCreationOptions: false,
      googleMeetEnabled: false,
      googleVars: {
        apiKey: null,
        clientId: null,
        discoveryDocs: null,
        scopes: null,
        isGoogleUserSignedIn: false,
      },
      copyToOtherClassroomDialog: {
        visible: false,
        classroom: null,
        classroomSubject: null,
      },
      roomSubjects: [],
      createEditConferenceSheet: {
        visible: false,
        isGoogleMeet: false,
        createSuccessMessage: "Online class created successfully!",
        editId: null,
        obj: {
          creator: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "allRooms",
    ]),
    shouldProceed() {
      var isStudentOrAdmin = this.currentUser.is_admin || this.currentUser.is_student;
      var roomSelected = this.selectedRoom != null;
      var isFacultyAndNotAdmin = this.currentUser.is_faculty && !this.currentUser.is_admin;
      return (isStudentOrAdmin && roomSelected) || isFacultyAndNotAdmin;
      
    },
    createEditDescription() {
      if (this.createEditConferenceSheet.isGoogleMeet)
        return "Fill in the required details and a Google Meet will be automatically created for you!";
      return "Link an online meeting from your favourite video conferencing platform.";
    },

    creationOptions() {
      return [
        {
          image: require("@assets/images/googlemeet.png"),
          title: "Google Meet",
          action: async () => {
            console.log(this.googleMeetEnabled);
            if (this.devicePlatform == "web" || this.googleMeetEnabled) {
              if (!this.googleVars.isGoogleUserSignedIn)
                await this.googleSignIn();
              this.createEditConferenceSheet.isGoogleMeet = true;
              this.createEditConferenceSheet.visible = true;
            }else {
              this.createEditConferenceSheet.visible = true;
            }
          },
        },
        {
          image: require("@assets/images/zoom.png"),
          title: "Zoom Meeting",
          action: () => (this.createEditConferenceSheet.visible = true),
        },
        {
          image: require("@assets/images/custom_link.png"),
          title: "Custom Link",
          action: () => (this.createEditConferenceSheet.visible = true),
        },
      ];
    },

    onlineClassesEndpoint() {
      if (!this.shouldProceed) return;
      var url = this.Helper.addUrlParams(this.endpoints.videoConferences, [
        "end_datetime__gte=" + moment().format("yyyy-M-D H:mm"),
        "ordering=start_datetime",
      ]);
      if (this.currentUser.is_student || this.currentUser.is_admin)
        return this.Helper.addUrlParams(
          url,
          "subject__room=" + this.selectedRoom.id
        );
      // if faculty
      return this.Helper.addUrlParams(
        url,
        "subject__faculty__id=" + this.currentUser.id
      );
    },

    previousOnlineClassesEndpoint() {
      if (!this.shouldProceed) return;
      var url = this.Helper.addUrlParams(this.endpoints.videoConferences, [
        "end_datetime__lte=" + moment().format("yyyy-M-D H:mm"),
        "ordering=-start_datetime",
      ]);
      if (this.currentUser.is_student || this.currentUser.is_admin)
        return this.Helper.addUrlParams(
          url,
          "subject__room=" + this.selectedRoom.id
        );
      // if faculty
      return this.Helper.addUrlParams(
        url,
        "subject__faculty__id=" + this.currentUser.id
      );
    },

    subjectsEndpoint() {
      if (this.currentUser.is_student || this.currentUser.is_admin)
        return this.Helper.addUrlParams(
          this.endpoints.subjectViewSet,
          "room=" + this.selectedRoom.id
        );
      // if faculty
      return this.Helper.addUrlParams(
        this.endpoints.subjectViewSet,
        "faculty=" + this.currentUser.id
      );
    },

    formFields() {
      if (!this.shouldProceed) return null;
      var fields = {
        title: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Title",
          required: true,
          md: 12,
          max: 255,
        },

        subject: {
          fieldType: CreateEditFieldTypes.SELECT,
          label: "Subject",
          md: 6,
          required: true,
          endpoint: this.subjectsEndpoint,
          items: [],
          itemText: "name",
          // itemSubtitle: "room.sections",
          itemValue: "id",
        },
        link: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Meeting Link",
          required: true,
          hide: this.createEditConferenceSheet.isGoogleMeet,
          md: 6,
          max: 400,
          pattern:
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
        },
        start_datetime: {
          fieldType: CreateEditFieldTypes.DATETIMEPICKER,
          textModel: null,
          min: new Date(),
          label: "Start Datetime",
          required: true,
          md: 6,
          customRules: [
            (v) =>
              !v ||
              !this.createEditConferenceSheet.obj.end_datetime ||
              moment(this.createEditConferenceSheet.obj.end_datetime).isAfter(
                moment(this.createEditConferenceSheet.obj.start_datetime)
              ) ||
              "Start Datetime should be before End Datetime",

            (v) =>
              !v ||
              moment(this.createEditConferenceSheet.obj.start_datetime).isAfter(
                moment()
              ) ||
              "Start Datetime cannot be before the current time.",
          ],
        },
        end_datetime: {
          fieldType: CreateEditFieldTypes.DATETIMEPICKER,
          textModel: null,
          hide: this.createEditConferenceSheet.obj.start_datetime == null,
          min: new Date(this.createEditConferenceSheet.obj.start_datetime),
          label: "End Datetime",
          required: true,
          md: 6,
          customRules: [
            (v) =>
              !v ||
              moment(this.createEditConferenceSheet.obj.end_datetime).isAfter(
                moment(this.createEditConferenceSheet.obj.start_datetime)
              ) ||
              "End Datetime should be after Start Datetime",
          ],
          // valid: () => moment(this.createEditConferenceSheet.obj.end_datetime).isAfter(this.createEditConferenceSheet.obj.start_datetime)
        },
      };
      return fields;
    },
  },

  methods: {

    async copyOnlineClass(){

        var url = this.endpoints.videoConferences;
        var postBody = {
          ...this.homework,
          id: null,
          title: this.currentOnlineClass.title,
          link: this.currentOnlineClass.link,
          creator: this.currentUser.id,
          start_datetime: this.currentOnlineClass.start_datetime,
          end_datetime: this.currentOnlineClass.end_datetime,
          subject: this.copyToOtherClassroomDialog.classroomSubject,
        }

        var response = await this.api.call(this.essentials, url, this.api.Methods.POST, postBody);
        
        if(response){
          this.showSnackbar({
            title: "Online Class copied successfully",
            type: "success",
          });
          
          // var self = this;
          // setTimeout(() => {
          //   self.$router.replace({
          //     name: "HomeworkDetails",
          //     params: { homeworkId: response.id },
          //   });
          // }, 1000);
        }

        this.copyToOtherClassroomDialog.visible = false; 
        this.copyToOtherClassroomDialog.classroom = null; 
        this.copyToOtherClassroomDialog.classroomSubject = null;
    },

    getDropdownOptions(conference) {
      var self = this;
      return [
        ...(!this.currentUser.is_student
          ? [
              {
                obj: conference,
                value: 1,
                text: "Copy Link",
                icon: "mdi-content-copy",
                async action() {
                  if (this.platform == "web"){
                    await self.Helper.copyText(conference.link);
                    self.showSnackbar({
                      title: "Copied to Clipboard",
                      type: "success",
                    });
                  }else{
                    Share.share({
                      url: conference.link,
                    });
                  }
                  
                },
              },
            ]
          : []), // copy link option should only be visible to faculty/admin

        ...(conference.subject.room
        ? [
            {
              obj: conference,
              value: 2,
              text: "Copy to Other Classroom",
              icon: "mdi-content-copy",
              type: "edit",
              action() {
                self.currentOnlineClass = conference;
                self.copyToOtherClassroomDialog.visible = true;
              },
            },
          ]
        : []),
        {
          obj: conference,
          value: 3,
          text: "Edit",
          icon: "mdi-pencil",
          type: "edit",
          action() {
            self.createEditConferenceSheet.editId = conference.id;
            self.createEditConferenceSheet.visible = true;
          },
        },
        {
          obj: conference,
          value: 4,
          text: "Delete",
          icon: "mdi-delete",
          type: "delete",
          action() {
            self.toBeDeletedOnlineClass = conference;
            self.showDeleteDialog = true;
          },
        },
      ];
    },

    async goToMeeting(conference) {
      window.open(conference.link, "_blank").focus();
    },

    async deleteOnlineClass() {
      var url =
      this.endpoints.videoConferences + this.toBeDeletedOnlineClass.id + "/";
      this.toBeDeletedOnlineClass = null;
      this.showDeleteDialog = false;
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.onRefresh();
        this.showSnackbar({
          title: "Online Class has successfully been deleted!",
          type: "success",
        });
      }
    },

    async googleSignIn() {
      var response = await GoogleAuth.signIn();
      // console.log(response);
      // console.log(response, gapi);
      if (this.devicePlatform != "web") {
        const body = {
          client_id:
            "357571717904-0bvvv59dq8peov2cg49rllafieqkg65t.apps.googleusercontent.com",
          client_secret: "sDVeYRLo-zhWdTFVW1HQUuvl",
          code: response.serverAuthCode,
          grant_type: "authorization_code",
          redirect_uri: window.location.href,
        };
        const authResponse = await this.api.call(
          this.essentials,
          "https://oauth2.googleapis.com/token",
          this.api.Methods.POST,
          body
        );
        gapi.auth.setToken({ access_token: authResponse.access_token });
        this.updateSigninStatus(true);
        this.showSnackbar({
          text: "Successfully signed in Google!",
          type: "success",
        });
      }
    },

    async initGoogleApis() {
      // init variables from file
      this.googleVars.clientId =
        "357571717904-pg4l703dfo4u11agfq16h5jg568ntgen.apps.googleusercontent.com";
      this.googleVars.apiKey = "AIzaSyBsKyRXwut-WYTz1A7xaZNlyHqFpjJizpk";

      // Array of API discovery doc URLs for APIs used by the quickstart
      this.googleVars.discoveryDocs = [
        "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
      ];

      // Authorization scopes required by the API; multiple scopes can be
      // included, separated by spaces.
      this.googleVars.scopes = "https://www.googleapis.com/auth/calendar";
      // import script
      const plugin = document.createElement("script");
      plugin.setAttribute("src", "//apis.google.com/js/api.js");
      plugin.async = true;
      plugin.defer = true;
      var self = this;
      function handleClientLoad() {
        gapi.load("client:auth2", self.initClient);
      }

      plugin.onload = () => {
        handleClientLoad();
      };
      plugin.onreadystatechange =
        "if (this.readyState === 'complete') this.onload()";
      document.head.appendChild(plugin);


      this.googleMeetEnabled = true;
    },

    async initClient() {
      var self = this;
      console.log("GAPI: ", gapi);
      gapi.client
        .init({
          apiKey: this.googleVars.apiKey,
          clientId: this.googleVars.clientId,
          discoveryDocs: this.googleVars.discoveryDocs,
          scope: this.googleVars.scopes,
        })
        .then(
          function () {
            // Listen for sign-in state changes.
            gapi.auth2
              .getAuthInstance()
              .isSignedIn.listen(self.updateSigninStatus);

            // Handle the initial sign-in state.
            self.updateSigninStatus(
              gapi.auth2.getAuthInstance().isSignedIn.get()
            );
            // authorizeButton.onclick = handleAuthClick;
            // signoutButton.onclick = handleSignoutClick;
          },
          function (error) {
            console.log(JSON.stringify(error, null, 2));
          }
        );
    },

    // async requestAllScopes() {
    //   const option = new gapi.auth2.SigninOptionsBuilder();
    //   option.setScope("https://www.googleapis.com/auth/calendar");
    //   option.setScope("https://www.googleapis.com/auth/calendar.events");

    //   var googleUser = gapi.auth2.currentUser.get();
    //   googleUser.grant(options).then(
    //     function (success) {
    //       console.log(JSON.stringify({ message: "success", value: success }));
    //     },
    //     function (fail) {
    //       alert(JSON.stringify({ message: "fail", value: fail }));
    //     }
    //   );
    // },

    updateSigninStatus(isSignedIn) {
      console.log("SIGN IN STATUS: ", isSignedIn);
      this.googleVars.isGoogleUserSignedIn = isSignedIn;
    },

    async createGoogleMeet(conference, closeDialogFunction) {
      var self = this;
      var event = {
        summary: conference.title,
        description: "Online Class Scheduled",
        start: {
          dateTime: conference.start_datetime,
          timeZone: "Asia/Kolkata",
        },
        end: {
          dateTime: conference.end_datetime,
          timeZone: "Asia/Kolkata",
        },
        conferenceDataVersion: 1,
        conferenceData: {
          createRequest: {
            conferenceSolutionKey: {
              type: "hangoutsMeet",
            },
            requestId: this.Helper.generateUID(),
          },
        },
      };
      this.setLoading(true);

      var request = gapi.client.calendar.events.insert({
        calendarId: "primary",
        resource: event,
        conferenceDataVersion: 1,
      });

      request.execute(async function (event) {
        conference.link = event.conferenceData.entryPoints[0].uri;
        var url = self.endpoints.videoConferences;
        if (conference.id) url + conference.id + "/"; // if editing
        var response = await self.api.call(
          self.essentials,
          url,
          conference.id ? self.api.Methods.PUT : self.api.Methods.POST,
          conference
        ); // now send conference to server
        if (response) {
          self.onRefresh();
          closeDialogFunction();
        }
      });

      return conference;
    },

    async onRefresh() {
      var el = this.$refs["online-classes-smart-list"];
      if (el) el.getItems();
      if (this.showPreviousOnlineClasses) {
        var previousList = this.$refs["previous-online-classes-smart-list"];
        if (previousList) previousList.getItems();
      }
    },
  },
  beforeDestroy() {
    gapi.auth2.getAuthInstance().signOut();
  },

  watch: {
    'copyToOtherClassroomDialog.classroom': async function(){
        // console.log(this.createEditActivitySheet.obj.rooms) 
        if(this.copyToOtherClassroomDialog.classroom){
          var url = this.Helper.addUrlParams(this.endpoints.subjectViewSet, [`room=${ this.copyToOtherClassroomDialog.classroom }`])
          // console.log(url);
          this.roomSubjects = await this.api.call(this.essentials, url)
          this.roomSubjects = this.roomSubjects.filter(subject => subject.id != this.currentOnlineClass.subject.id);
       }
      }
  }, 

  async created() {
    this.showRoomDialog = this.currentUser.is_admin; //only show room dialog when user is admin
    
    this.devicePlatform = await this.Helper.getDevicePlatform();
    if (this.currentUser.is_admin || this.currentUser.is_faculty) {
      if (this.devicePlatform == "web") {
        GoogleAuth.init();
        this.initGoogleApis(); 
      }else{ 
        if(!this.currentInstitution.preferences.disable_google_meet) this.initGoogleApis();
      }
      // this.initGoogleApis();
    }
    
    this.createEditConferenceSheet.obj.creator = this.currentUser.id;
  },
};
</script>